<template>
  <div>
    <!-- Modal de redirigir a sección plan favorito -->
    <ModalConfirm
      ref="confirmModalFavorite"
      id="confirmModalFavorite"
      title="Plan favorito guardado"
      message='Su plan ha sido guardado correctamente. ¿Desea que sea redirigido a la sección "Planes aducción favoritos"?'
      confirm="Sí, redirigirme ahora"
      reject="No, seguir en esta sección"
      @confirmClick="$router.push({ name: 'favorite-plan' })"
    />
    <!-- Modal de actualizar plan favorito -->
    <ModalConfirm
      ref="updateFavoriteSuccessModal"
      id="updateFavoriteSuccessModal"
      title="Plan favorito actualizado con éxito"
      message='Su plan ha sido actualizado correctamente ¿Quiere volver a la pantalla de inicio?'
      confirm="Aceptar"
      reject="No, seguir en esta sección"
      @confirmClick="$router.push({ name: 'wizard-step-0' })"
    />
    <!-- Modal de reusar plan -->
    <ModalConfirm
      ref="confirmModalReuse"
      id="confirmModalReuse"
      title="Reusar parámetros"
      message='¿Desea reutilizar los parámetros como base para recalcular un nuevo plan de aducción?'
      confirm="Sí, reutilizar parámetros"
      reject="No, seguir aquí"
      @confirmClick="reusePlanConfirm"
    />
    <div class="d-flex justify-content-between">
      <button :class="isReusable ? 'd-inline' : 'd-none'" type="button" class="btn btn-primary me-2" @click="reusePlan" title="Usar configuración guardad para generar un plan de aducción nuevo">
        <i class="fa-solid fa-recycle"></i>
        <span class="ms-2">Reusar plan</span>
      </button>      
      <button type="button" class="btn btn-success me-2" @click="savePlan" title="Guardar modificaciones">
        <i class="fas fa-save"></i>
        <span class="ms-2">Guardar plan</span>
      </button>
      <DownloadButton
        id="expxlsx"
        label="Exportar Excel"
        compClass="btn-warning"
        title="Exportar plan aduccion en formato Excel"
        endpoint="/media/download/file/"
      />
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import BlockFull from "@/components/commons/BlockFull";
import PrevisionService from "@/services/PrevisionService";
import DownloadButton from "@/components/commons/DownloadButton";
import ModalConfirm from "@/components/commons/ModalConfirm";

export default {
  data() {
    return {
      selectedPlan: null,
      idPlan: null
    };
  },
  props: {
    redirectToFavorites: {
      type: Boolean,
      default: false
    },
    isFavoriteView: {
      type: Boolean,
      default: false
    },
    idPlanFavorite: {
      type: Number,
      default: null
    },
    isReusable:{
      type: Boolean,
      default: false
    }
  },
  components: {
    BlockFull,
    DownloadButton,
    ModalConfirm,
  },
  computed: {
    ...mapState("planner", [
      "v_dataSet",
      "v_fullDataSet",
      "v_selectedPlan",
      "v_selectedYear",
      "v_selectedCriteria",
      "v_availability",
      "v_volumes",
    ])
  },
  methods: {
    ...mapActions("planner", ["v_updSelectedPlan", "v_updDataSet"]),

    async savePlan() {
      //Este retrieveData consigue el id de plan de la base de datos para plan nuevo, sumándole uno.
      if(this.isFavoriteView){
        this.idPlan = this.idPlanFavorite;
      }
      else{
        //Este retrieveData consigue el id de plan de la base de datos para plan nuevo, sumándole uno.
        await this.retrieveData();
      }

      //Este bucle for recorre los 4 arrays de datos a la vez por día de quincena, itera sombre planVolumen porque tiene una fila más
      //prepara los datos para insertar en la base de datos por embalse y día y ejecuta el post o el put según la vista.
      for (let i = 0; i < this.v_dataSet.planVolumen.filas.length; i++) {
        //Declaro las variables que usaremos, incluyendo los datos de Aducción, volumen, etc para facilitar la lectura.
        let datosAracena = { nombre: "Aracena" };
        let datosZufre = { nombre: "Zufre" };
        let datosMinilla = { nombre: "La Minilla" };
        let datosGergal = { nombre: "El Gergal" };
        let datosCala = { nombre: "Cala" };
        let datosMelonares = { nombre: "Los Melonares" };
        let datosAduccion;
        let datosTrasvases;
        let datosRejaustes;
        let datosVolumen= this.v_dataSet.planVolumen.filas[i];
        let datosDisponibilidad = this.v_availability;
        let datosVolumenes = this.v_volumes;
        let datosInsercion;

        //Los meto en un array, ordenándolos según si vamos a guardar nuevo o actualizar favorito:
        if(this.isFavoriteView){
          datosInsercion = [
            datosAracena,
            datosCala,
            datosGergal,
            datosMinilla,
            datosMelonares,
            datosZufre
          ];

        }
        else{
          datosInsercion = [
            datosAracena,
            datosZufre,
            datosMinilla,
            datosGergal,
            datosCala,
            datosMelonares
          ];
        }

        //Si estamos en el ultimo loop del bucle controlo el nullpointer de los planes que no son Volumen:
        if(i == this.v_dataSet.planVolumen.filas.length-1){
          datosAduccion = this.v_dataSet.planAduccion.filas[i-1];
          datosTrasvases = this.v_dataSet.planTrasvases.filas[i-1];
          datosRejaustes = this.v_dataSet.planReajustes.filas[i-1];
        }
        else{
          datosAduccion = this.v_dataSet.planAduccion.filas[i];
          datosTrasvases = this.v_dataSet.planTrasvases.filas[i];
          datosRejaustes = this.v_dataSet.planReajustes.filas[i];
        }

        if(this.isFavoriteView){
          //Como los Datos de Aducción tienen una estructura diferente, añado los valores a mano:
          datosMinilla["aduccionM"] = datosAduccion.masasAgua[2].caudal.value != '-'?datosAduccion.masasAgua[2].caudal.value:null;
          datosMinilla["aduccionH"] = datosAduccion.masasAgua[2].extraido.value!= '-'?datosAduccion.masasAgua[2].extraido.value:null;
          datosMelonares["aduccionM"] = datosAduccion.masasAgua[3].caudal.value!= '-'?datosAduccion.masasAgua[3].caudal.value:null;
          datosMelonares["aduccionH"] = datosAduccion.masasAgua[3].extraido.value!= '-'?datosAduccion.masasAgua[3].extraido.value:null;
          datosGergal["aduccionM"] = datosAduccion.masasAgua[1].caudal.value!= '-'?datosAduccion.masasAgua[1].caudal.value:null;
          datosGergal["aduccionH"] = datosAduccion.masasAgua[1].extraido.value!= '-'?datosAduccion.masasAgua[1].extraido.value:null;


        }else{
          //Como los Datos de Aducción tienen una estructura diferente, añado los valores a mano:
          datosMinilla["aduccionM"] = datosAduccion.masasAgua[1].caudal.value != '-'?datosAduccion.masasAgua[1].caudal.value:null;
          datosMinilla["aduccionH"] = datosAduccion.masasAgua[1].extraido.value!= '-'?datosAduccion.masasAgua[1].extraido.value:null;
          datosGergal["aduccionM"] = datosAduccion.masasAgua[3].caudal.value!= '-'?datosAduccion.masasAgua[3].caudal.value:null;
          datosGergal["aduccionH"] = datosAduccion.masasAgua[3].extraido.value!= '-'?datosAduccion.masasAgua[3].extraido.value:null;
          datosMelonares["aduccionM"] = datosAduccion.masasAgua[2].caudal.value!= '-'?datosAduccion.masasAgua[2].caudal.value:null;
          datosMelonares["aduccionH"] = datosAduccion.masasAgua[2].extraido.value!= '-'?datosAduccion.masasAgua[2].extraido.value:null;
        }

        //Recorro el array de emabalses para añadir los datos:
        for (let j = 0; j < datosInsercion.length; j++) {
          //Datos comunes:
          datosInsercion[j]["idPlan"] = this.idPlan;
          datosInsercion[j]["fecha"] =
            this.v_selectedYear +
            "-" +
            datosAduccion.fecha[3] +
            datosAduccion.fecha[4] +
            "-" +
            datosAduccion.fecha[0] +
            datosAduccion.fecha[1];
          datosInsercion[j]["dias"] = datosVolumen.ndias;
          datosInsercion[j]["criteria"] = this.v_selectedCriteria;
          //Datos de volumen:
          datosInsercion[j]["volumenEmbalsado"] =
            (datosVolumen.masasAgua[j].extraido.value != '-'&& datosVolumen.masasAgua[j].extraido.value !='NaN')?datosVolumen.masasAgua[j].extraido.value:null;
          //Datos de trasvases:
          datosInsercion[j]["trasvases"] =
            datosTrasvases.masasAgua[j].extraido.value != '-'?datosTrasvases.masasAgua[j].extraido.value:null;
          //Datos de trasvases editados:
          datosInsercion[j]["trasvasesU"] =
            datosTrasvases.masasAgua[j].editado?1:null;
          //Datos de reajustes:
          datosInsercion[j]["reajustes"] =
            datosRejaustes.masasAgua[j].extraido.value!= '-'?datosRejaustes.masasAgua[j].extraido.value:null;
          //Datos de reajustes editados:
          datosInsercion[j]["reajustesU"] =
            datosRejaustes.masasAgua[j].editado?1:null;
          //Datos de disponibilidad
          datosInsercion[j]["disponibilidad"] =
            datosDisponibilidad[datosInsercion[j].nombre][i];
          //Datos de volumen
          for (let l = 0; l < datosVolumenes.length; l++) {
            if (datosVolumenes[l].Nombre_Embalse == datosInsercion[j].nombre) {

              if(datosInsercion[j]["fecha"].includes("11-01") && datosVolumenes[l]['01/11']){
                datosInsercion[j]["volMax"] = datosVolumenes[l]['01/11'].volMax;
                datosInsercion[j]["volMin"] = datosVolumenes[l]['01/11'].volMin;
              }
              else{
                datosInsercion[j]["volMax"] = datosVolumenes[l]['01/01'].volMax;
                datosInsercion[j]["volMin"] = datosVolumenes[l]['01/01'].volMin;
              }
            }
          }
          //Al acabar la quincena, volvemos a comprobar que es la útlima iteración.
          //si es asi significa que hemos llegado a final de año y tenemos que crear una inserción adicional
          //para el 01/01 del año siguiente, con los datos del planVolumen correctos. El resto dan igual pues no se consultan.
          //Finalmente se suben los datos de todos los embalses y se empieza el bucle para la siguiente.
          if (i == this.v_dataSet.planVolumen.filas.length - 1) {
            datosInsercion[j]["fecha"] = this.v_selectedYear + 1 + "-01-01";
            datosInsercion[j]["disponibilidad"] =0;
          }

          if(this.isFavoriteView){
            this.putFavoritePlan(datosInsercion[j]);
          }
          else{
            this.postFavoritePlan(datosInsercion[j]);
          }
        }
      }
    },
    //Método para recuperar datos
    async retrieveData() {
      //Llamada al servicio de obtención del último ID del plan y sumarle uno para forzar el auto incremento
      await PrevisionService.getIdPlan()
        .then((response) => {
          this.idPlan = response.data[0].ID + 1;
        })
        .catch((err) => {
          console.log(
            "Se ha producido un error al solicitar los volúmenes: " + err
          );
        });
    },
    //Método para subir a la base de datos el plan favorito
    postFavoritePlan(datosPlan) {
      PrevisionService.postFavoritePlan(datosPlan)
        .then((response) => {
          if(this.redirectToFavorites == true){
            this.$refs.confirmModalFavorite.showModal();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //Método para actualizar en a la base de datos el plan favorito
    putFavoritePlan(datosPlan) {
      PrevisionService.putFavoritePlan(datosPlan)
        .then((response) => {
          if(this.redirectToFavorites == false){
            this.$refs.updateFavoriteSuccessModal.showModal();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // Reusar plan
    reusePlan(){
       this.$refs.confirmModalReuse.showModal();
    },
    reusePlanConfirm(){
      this.$emit('reusePlan')
    }
  },
  async mounted() {},
  watch: {
    
  },
};
</script>