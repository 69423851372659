<template>
  <div>
    <button
      class="btn"
      :class="compClass"
      type="button"
      :disabled="isDisabled"
      :id="id"
      @click="downloadManager"
    >
      <i class="fas fa-file-download me-2"></i>
      <span v-if="isGenerating" class="span-advanced-settings"
        >Generando fichero...
      </span>
      <span v-if="isDownloading" class="span-advanced-settings"
        >Descargando... {{ progressBar }} Mb
      </span>
      <span
        v-if="isDownloading == false && isGenerating == false"
        class="span-advanced-settings"
      >
        {{ localLabel }}
      </span>
    </button>
  </div>
</template>



<script>
import { mapState } from "vuex";
import MediaService from "@/services/MediaService";
import PlannerService from "@/services/PlannerService";

export default {
  name: "DownloadButton",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "...",
    },
    compClass: {
      type: String,
    },
    endpoint: {
      type: String,
      required: true,
      default: null,
    },
    customfilename: {
      type: String,
      default: "fichero",
    }
  },
  computed: {
    ...mapState("media", ["downloadQueue"]),
    ...mapState("planner", ["v_uuid", "v_selectedYear", "v_dataSet", "v_selectedCriteria"]),
    progressBar: function () {
      if (this.downloadQueue) {
        let index = this.downloadQueue.findIndex((x) => x.id == this.id);
        if (index === -1) {
          // Devolvemos 0 en caso no posible
          return 0;
        } else {
          return this.downloadQueue[index].downloadProgress;
        }
      }
    },
    localLabel: function () {
      if (!this.isDownloading && this.endpoint) {
        return this.label;
      } else {
        return "Endpoint incorrecto";
      }
    },
  },
  data() {
    return {
      isDownloading: false,
      isGenerating: false,
      isDisabled: false,
    };
  },
  mounted() {},
  methods: {
    /* GESTOR DE DESCARGA */
    async downloadManager(event) {
      event.preventDefault();

      /* Si no se está generando ni descargando */
      if (!this.isGenerating && !this.isDownloading) {
        this.isGenerating = true;
        this.isDisabled = true;
        
        // Solicitar generar fichero
        this.requestGenerateExcel().then(() => {
          this.isGenerating = true;
        });
      }
    },


    /* Solicitar generar fichero */
    async requestGenerateExcel() {
        let data = {
            uuid: this.v_uuid,
            year: this.v_selectedYear,
            criteria: this.v_selectedCriteria,
            planAduccion: this.v_dataSet.planAduccion,
            planVolumen: this.v_dataSet.planVolumen,
            planReajustes: this.v_dataSet.planReajustes,
            planTrasvases: this.v_dataSet.planTrasvases            
        }      
        console.log(data)
        PlannerService.requestFile(data)
         .then(async (response) => {
            // Una vez finalizada la request
            this.isGenerating = false
            this.isDownloading = true
            console.log(response.data)
            await this.downloadFile(response.data)
          })
          .catch((error) => {
            this.isGenerating = false
          });
    },

    /*  */
    async downloadFile(data) {
      let urlFinal = this.endpoint + data.genExcelFilename.filename
      if (this.endpoint != null) {
        MediaService.downloadFile(this.id, urlFinal, data.genExcelFilename.customfilename)
          .then(async (response) => {
            //Download Files
            var data = new Blob([response.data]);
            var url = window.URL.createObjectURL(data);
            var link = document.createElement("a");
            var filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            document.body.appendChild(link); // Required for FF
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            this.isDownloading = false;
            this.isGenerating = false;
            this.isDisabled = false;
          })
          .catch((error) => {
            this.isDownloading = false
            this.isGenerating = false
            this.isDisabled = false;
          });
      }
    },
  },
};
/*

    generatedExcel: function (newVal, oldVal) {
      if (
        this.v_dataSet &&
        this.v_dataSet.genExcelFilename &&
        this.v_dataSet.genExcelFilename.filename &&
        this.v_dataSet.genExcelFilename.filename != null
      ) {
        console.log(this.v_dataSet.genExcelFilename.filename)
        return this.v_dataSet.genExcelFilename.filename;
      } else {
        return "";
      }
    },
    generatedExcelOutputFilename: function (newVal, oldVal) {
      if (
        this.v_dataSet &&
        this.v_dataSet.genExcelFilename &&
        this.v_dataSet.genExcelFilename.customfilename &&
        this.v_dataSet.genExcelFilename.customfilename != null
      ) {
        return this.v_dataSet.genExcelFilename.customfilename;
      } else {
        return "";
      }*/
</script>

<style scoped>
</style>