<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" style="background: #305680; color: #fff;">
          <h5 class="modal-title" id="exampleModalLabel" v-if="title != null">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="groupBox">
              <div class="card mt-3" id="datacard">
                <div class="card-body me-2">
                  <div class="row">
                    <div class="col">
                      <p>{{ message }}</p>
                      <!-- SLOT para elementos extras-->
                      <slot name="modalBody"></slot>
                      <!-- SLOT  para elementos extras -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ reject }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            :id="localConfirmBtn"
            @click="clickConfirm"
          >
            {{ confirm }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from "bootstrap";

export default {
  name: "Modal",
  props: {
    id: {
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: "",
    },
    confirm: {
      type: String,
      default: "",
    },
    reject: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    localConfirmBtn: function () {
      return this.id + "confirmModalBtn";
    },
  },
  methods: {
    showModal(){
      let modalElelent = document.getElementById(this.id);
      let modal = Modal.getOrCreateInstance(modalElelent);
      modal.show();
    },      
    closeModal(){
      let modalElelent = document.getElementById(this.id);
      let modal = Modal.getOrCreateInstance(modalElelent);
      modal.hide();
    },
    async clickConfirm(){
      await this.closeModal()
      await this.$emit('confirmClick')
    }
  },
};
</script>