<template>
  <!-- Tabla de datos-->
  <div class="table-responsive mt-3">
    <table class="table table-hover">
      <thead class="table-light">
        <!-- Primera serie de columnas-->
        <tr>
          <th class="text-left ps-3">Período</th>
          <th class="text-center">Total hm3</th>
          <th class="text-center">Total MWh</th>
        </tr>
      </thead>

      <tbody class="text-left dataTable">
        <tr>
          <td class="ps-3">Desde 01/01 se turbina en Aracena</td>
          <td class="text-center">{{ aracenaVolTotal }}</td>
          <td class="text-center">{{ aracenaEnergiaTotal }}</td>
        </tr>
        <tr>
          <td class="ps-3">Desde 01/01 se turbina en Zufre</td>
          <td class="text-center">{{ zufreVolTotal }}</td>
          <td class="text-center">{{ zufreEnergiaTotal }}</td>
        </tr>
        <tr>
          <td class="ps-3">Desde 01/01 se turbina en La Minilla</td>
          <td class="text-center">{{ minillaVolTotal }}</td>
          <td class="text-center">{{ minillaEnergiaTotal }}</td>
        </tr>
        <tr>
          <td class="ps-3"><b>Volumen total turbinado</b></td>
          <td class="text-center">
            <b>{{ genVolTotal }}</b>
          </td>
          <td class="text-center">
            <b>{{ genEnergiaTotal }}</b>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="legend ms-2">
    <span>Volumen turbinado "Aracena": Trasvase del año entero "Aracena"</span><br>
    <span>Volumen turbinado "Zufre": Trasvase del año entero "Zufre"</span><br>
    <span>Volumen turbinado "La Minilla": Aducido del año entero "La Minilla" + Trasvase del año entero "La Minilla"</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    dataSet: {
      type: Object,
      required: true,
    },
    viewMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  watch: {},
  computed: {
    aracenaVolTotal: function (val) {
      if(this.dataSet && this.dataSet.planTrasvases){
        let sum = 0;
        let index = -1;
        for(let i=0; i<this.dataSet.planTrasvases.filas.length; i++){
            index = (this.dataSet.planTrasvases.filas[i].masasAgua).findIndex(x =>  x.label.includes("Aracena"));
            sum = sum + ((this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value != '-' && typeof  this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value !== "undefined" )? parseFloat(this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value) : 0 )
        }
        return sum.toFixed(3);
      }else{
         return 0;
      }
    },
    aracenaEnergiaTotal: function (val) {
      return (this.aracenaVolTotal * 108).toFixed(3);
    },
    zufreVolTotal: function (val) {
      if(this.dataSet && this.dataSet.planTrasvases){
        let sum = 0;
        let index = -1;
        for(let i=0; i<this.dataSet.planTrasvases.filas.length; i++){
            index = (this.dataSet.planTrasvases.filas[i].masasAgua).findIndex(x =>  x.label.includes("Zufre"));
            sum = sum + ((this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value != '-' && typeof this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value !== "undefined" ) ? parseFloat(this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value) : 0 )
        }
        return sum.toFixed(3);
      }else{
         return 0;
      }
    },
    zufreEnergiaTotal: function (val) {
      return (this.zufreVolTotal * 115).toFixed(3);
    },
    minillaVolTotal: function (val) {
      if(this.dataSet && this.dataSet.planTrasvases){
        let sum = 0;
        let index = -1;
        for(let i=0; i<this.dataSet.planTrasvases.filas.length; i++){
            index = (this.dataSet.planTrasvases.filas[i].masasAgua).findIndex(x =>  x.label.includes("Minilla"));
            sum = sum + ((this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value != '-' && typeof this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value !== "undefined") ? parseFloat(this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value) : 0 )
        }
        for(let i=0; i<this.dataSet.planAduccion.filas.length; i++){
            index = (this.dataSet.planAduccion.filas[i].masasAgua).findIndex(x =>  x.label.includes("Minilla"));
            sum = sum + ((this.dataSet.planAduccion.filas[i].masasAgua[index].extraido.value != '-' && typeof this.dataSet.planAduccion.filas[i].masasAgua[index].extraido.value !== "undefined")  ? parseFloat(this.dataSet.planAduccion.filas[i].masasAgua[index].extraido.value) : 0 )
        }        
        return sum.toFixed(3);
      }else{
         return 0;
      }
    },
    minillaEnergiaTotal: function (val) {
       return (this.minillaVolTotal * 100).toFixed(3);
    },
    genVolTotal: function (val) {
      return (parseFloat(this.aracenaVolTotal) + parseFloat(this.zufreVolTotal) + parseFloat(this.minillaVolTotal)).toFixed(3);
    },
    genEnergiaTotal: function (val) {
      if(this.aracenaEnergiaTotal && this.zufreEnergiaTotal && this.minillaEnergiaTotal){
          return (parseFloat(this.aracenaEnergiaTotal) + parseFloat(this.zufreEnergiaTotal) + parseFloat(this.minillaEnergiaTotal)).toFixed(3);
      }else{
        return 0
      }
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
/* Estilo de la tabla */
table {
  border: 1px solid #dfe6ec;
}
table th {
  font-size: 0.9rem;
  border-right: 1px solid #dfe6ec;
}

table td {
  font-size: 0.85rem;
  border-right: 1px solid #dfe6ec;
}
.legend{
  font-size: 0.9rem;
  padding: 2px;
}
</style>