<template>
  <div>
    <!-- PESTAÑAS -->
    <div class="card">
      <div class="card-body h-100 p-0">
        <div class="d-flex align-items-start">
          <div class="flex-grow-1">
            <!-- INICIO -->
            <ul class="nav nav-tabs nav-fill w-100">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="planAduccion"
                  data-bs-toggle="tab"
                  href="'#ssplanAduccion"
                  role="tab"
                  aria-controls="planAduccion"
                  aria-selected="true"
                  ><i class="fa-solid fa-water"></i> Plan de aduccion</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="volumenEmbalsado"
                  data-bs-toggle="tab"
                  href="'#ssvolEmbalsado"
                  role="tab"
                  aria-controls="volumenEmbalsado"
                  aria-selected="true"
                  ><i class="fa-solid fa-arrow-up-from-water-pump"></i> Volumen
                  embalsado</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="volTrasvases"
                  data-bs-toggle="tab"
                  href="'#ssvolTrasvases"
                  role="tab"
                  aria-controls="volTrasvases"
                  aria-selected="true"
                  ><i class="fa-solid fa-bridge-water"></i> Trasvases / Caudal Ecológico</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="volReajustes"
                  data-bs-toggle="tab"
                  href="'#ssvolReajustes"
                  role="tab"
                  aria-controls="volReajustes"
                  aria-selected="true"
                  ><i class="fa-solid fa-cloud-showers-water"></i> Aportaciones / Reajustes</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- PESTAÑAS -->

    <!-- TABS -->
    <div class="row mt-3 ms-1 me-1">
      <div class="tab-content p-0" id="myTabContent">
        <!-- TAB Plan Aduccion -->
        <div
          class="tab-pane fade show active"
          id="ssplanAduccion"
          role="tabpanel"
          aria-labelledby="ssplanAduccion-tab"
        >
          <AreaChart
            ref="planAduccionChart"
            class="card p-2"
            chartID="chartplanAduccion"
            :chartTitle="caudalesChartTitle"
            :dataSet="v_dataSet"
            selDataSet="planAduccion"
            yAxisUnit="m3/s"
          />
          <DataTable
            ref="planAduccionTable"
            globalID="planAduccionTable"
            :dataSet="
              v_dataSet && v_dataSet.planAduccion ? v_dataSet.planAduccion : {}
            "
            :isEditable="viewMode ? false : true"
            @updateValues="updateAduccion"
            :planFromDataBase="dataSetFromDatabase? true: false"
          />
        </div>

        <!-- TAB Volumen embalsado -->
        <div
          class="tab-pane fade show"
          id="ssvolEmbalsado"
          role="tabpanel"
          aria-labelledby="ssvolEmbalsado-tab"
        >
          <AreaChart
            ref="volEmbalsadoChart"
            class="card p-2"
            chartID="chartplanVolumen"
            :chartTitle="volumenesChartTitle"
            selDataSet="planVolumen"
            :dataSet="v_dataSet"
            yAxisUnit="hm3"
          />
          <DataTable
            ref="volEmbalsadoTable"
            globalID="volEmbalsadoTable"
            :dataSet="
              v_dataSet && v_dataSet.planVolumen ? v_dataSet.planVolumen : {}
            "
            :isEditable="false"
          />
        </div>

        <!-- TAB Volumen Reajustes -->
        <div
          class="tab-pane fade show"
          id="ssvolReajustes"
          role="tabpanel"
          aria-labelledby="ssvolReajustes-tab"
        >
          <!-- Graficas reactivas -->
          <div class="row mt-3">
            <div class="col-12 col-md me-md-1 mb-3">
              <AreaChart
                ref="planAduccionChart2"
                :dataSet="v_dataSet"
                chartID="chartplanAduccion2"
                selDataSet="planAduccion"
                yAxisUnit="m3/s"
                estilo="width: 100%; height: 250px;"
                :yAxisLabel="false"
                :basicMode="true"
                style="border: 1px solid #ccc; padding: 5px"
              />
            </div>
            <div class="col-12 col-md mt-3 mt-md-0 ms-md-1">
              <AreaChart
                ref="volEmbalsadoChart2"
                :dataSet="v_dataSet"
                chartID="chartplanVolumen2"
                selDataSet="planVolumen"
                yAxisUnit="hm3"
                estilo="width: 100%; height: 250px;"
                :yAxisLabel="false"
                :basicMode="true"
                style="border: 1px solid #ccc; padding: 5px"
              />
            </div>
          </div>
          <DataTable
            ref="volReajustesTable"
            globalID="volReajustesTable"
            :dataSet="
              v_dataSet && v_dataSet.planReajustes
                ? v_dataSet.planReajustes
                : {}
            "
            :isEditable="viewMode ? false : true"
            @updateValues="updateReajustes"
            :planFromDataBase="dataSetFromDatabase? true: false"
          />
        </div>

        <!-- TAB Volumen Trasvases -->
        <div
          class="tab-pane fade show"
          id="ssvolTrasvases"
          role="tabpanel"
          aria-labelledby="ssvolTrasvases-tab"
        >
          <!-- Graficas reactivas -->
          <div class="row mt-3">
            <div class="col-12 col-md me-md-1 mb-3">
              <AreaChart
                ref="planAduccionChart3"
                :dataSet="v_dataSet"
                chartID="chartplanAduccion3"
                selDataSet="planAduccion"
                yAxisUnit="m3/s"
                estilo="width: 100%; height: 250px;"
                :yAxisLabel="false"
                :basicMode="true"
                style="border: 1px solid #ccc; padding: 5px"
              />
            </div>
            <div class="col-12 col-md mt-3 mt-md-0 ms-md-1">
              <AreaChart
                ref="volEmbalsadoChart3"
                :dataSet="v_dataSet"
                chartID="chartplanVolumen3"
                selDataSet="planVolumen"
                yAxisUnit="hm3"
                estilo="width: 100%; height: 250px;"
                :yAxisLabel="false"
                :basicMode="true"
                style="border: 1px solid #ccc; padding: 5px"
              />
            </div>
          </div>
          <DataTable
            ref="volTrasvasesTable"
            globalID="volTrasvasesTable"
            :dataSet="
              v_dataSet && v_dataSet.planTrasvases
                ? v_dataSet.planTrasvases
                : {}
            "
            :isEditable="viewMode ? false : true"
            @updateValues="updateTrasvases"
            :planFromDataBase="dataSetFromDatabase? true: false"
          />
        </div>
      </div>
    </div>
    <!-- TABS -->
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import DataTable from "./../DataTable";
import AreaChart from "./../AreaChart";
import Select from "@/components/commons/Select";

export default {
  data() {
    return {};
  },
  props: {
    viewMode: {
      type: Boolean,
      default: true,
    },
    dataSetFromDatabase:{
      type: Boolean, 
      default: true
    }
  },
  components: {
    Select,
    DataTable,
    AreaChart,
  },
  watch: {
    selectedYear: function (newVal, oldVal) {
      if (newVal != null) {
        this.v_updSelectedYear(newVal);
      }
    },

    selectedCriteria: function (newVal, oldVal) {
      if (newVal != null) {
        this.v_updSelectedCriteria(newVal);
      }
    },

    attachmentFilename: function (newVal, oldVal) {
      if (newVal != null) {
        this.v_updSelectedFilename(newVal);
      }
    },
    v_dataSet: function (newVal, oldVal) {
      return newVal;
    },
  },
  computed: {
    ...mapState("planner", ["v_selectedYear", "v_dataSet"]),
    caudalesChartTitle: function (val) {
      let titulo = "CAUDALES EN " + this.v_selectedYear;
      return titulo;
    },
    volumenesChartTitle: function (val) {
      let titulo = "VOLÚMENES EN " + this.v_selectedYear;
      return titulo;
    },
    reajustesTitle: function (val) {
      let titulo = "REAJUSTES EN " + this.v_selectedYear;
      return titulo;
    },
    trasvasesTitle: function (val) {
      let titulo = "TRASVASES EN " + this.v_selectedYear;
      return titulo;
    },
  },
  methods: {
    ...mapActions("planner", [
      "v_updSelectedYear",
      "v_updSelectedCriteria",
      "v_updSelectedFilename",
      "v_updDataSetAduccion",
      "v_updDataSetTransfers",
      "v_updDataSetReadjustments",
      "v_updDataSetVolumes",
    ]),

    async updateAduccion(newDataSet, pos) {
      // Importante el orden
      await this.v_updDataSetAduccion(await this.recalcFormat(newDataSet, pos));
      //await this.v_updDataSetVolumes(await this.recalculateValues(pos));
    },
    async updateTrasvases(newDataSet, pos) {
      // Importante el orden
      await this.v_updDataSetTransfers(newDataSet);
      await this.v_updDataSetVolumes(await this.recalculateValues(pos));
    },
    async updateReajustes(newDataSet, pos) {
      // Importante el orden
      this.v_updDataSetReadjustments(newDataSet);
      await this.v_updDataSetVolumes(await this.recalculateValues(pos));
    },

    /** RECALCULO DE HM3 A M3S */
    async recalcFormat(newDataSet, pos) {
      try {
        // Copia objeto
        let planAduccionAct = await this.copyObject(newDataSet);
        // INDICE
        let index = newDataSet.filas[pos[1]].masasAgua.findIndex((x) =>
          x.label.includes("Total Aducido")
        );
        // Valores
        //console.log(planAduccionAct.filas[pos[1]].masasAgua);
        planAduccionAct.filas[pos[1]].masasAgua[pos[2]].caudal.value = (
          parseFloat(
            planAduccionAct.filas[pos[1]].masasAgua[pos[2]].extraido.value
          ) /
          (parseFloat(planAduccionAct.filas[pos[1]].ndias) * 0.0864)
        ).toFixed(3);
        let sum = 0;
        for (
          let z = 0;
          z < planAduccionAct.filas[pos[1]].masasAgua.length;
          z++
        ) {
          if (
            planAduccionAct.filas[pos[1]].masasAgua[z].label != "Total Aducido"
          ) {
            if (
              parseFloat(
                planAduccionAct.filas[pos[1]].masasAgua[z].extraido.value
              )
            )
              sum =
                parseFloat(sum) +
                parseFloat(
                  planAduccionAct.filas[pos[1]].masasAgua[z].extraido.value
                );
          }
        }
        planAduccionAct.filas[pos[1]].masasAgua[index].extraido.value = sum;
        planAduccionAct.filas[pos[1]].masasAgua[index].caudal.value = (
          parseFloat(
            planAduccionAct.filas[pos[1]].masasAgua[index].extraido.value
          ) /
          (parseFloat(planAduccionAct.filas[pos[1]].ndias) * 0.0864)
        ).toFixed(3);
        return planAduccionAct;
      } catch (e) {
        console.log("Ha ocurrido un error calculando: " + e);
      }
    },

    /* SANITAZIE VALUES */
    async sanitize(data){
      return data && data !="-" ? data : '0'
    },

    /* RECALCULO DE VOLÚMENES EN CALIENTE SUIGUIENDO LAS FORMULAS INDICADAS */
    async recalculateValues(pos) {
      try {
        let planVolumenAct = await this.copyObject(this.v_dataSet.planVolumen);
        // Filtro de calculo por cada embalse
        /* Vol aracena proxima quincena = Volumen aracena actual + reajuste aracena actual - trasvase aracena actual  */
        /************************************************************************************************* */
        if (
          this.v_dataSet.planTrasvases.filas[pos[1]].masasAgua[pos[2]].label ==
          "Aracena"
        ) {
          for (
            let z = parseInt(pos[1]);
            z < planVolumenAct.filas.length - 1;
            z++
          ) {
            planVolumenAct.filas[z + 1].masasAgua[pos[2]].extraido.value = (
              parseFloat(
                planVolumenAct.filas[z].masasAgua[pos[2]].extraido.value
              ) +
              parseFloat(
                this.v_dataSet.planReajustes.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) -
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[pos[2]].extraido
                  .value
              )
            ).toString();
            /*console.log(planVolumenAct.filas[z].masasAgua[pos[2]].extraido.value,
              this.v_dataSet.planReajustes.filas[z].masasAgua[pos[2]].extraido
                .value,
              this.v_dataSet.planTrasvases.filas[z].masasAgua[pos[2]].extraido
                .value,
              planVolumenAct.filas[z + 1].masasAgua[pos[2]].extraido.value
            );*/
          }
          /* Vol cala proxima quincena = vol cala + reajuste cala - trasvase cala */
          /************************************************************************************************* */
        } else if (
          this.v_dataSet.planTrasvases.filas[pos[1]].masasAgua[pos[2]].label ==
          "Cala"
        ) {
          for (
            let z = parseInt(pos[1]);
            z < planVolumenAct.filas.length - 1;
            z++
          ) {
            planVolumenAct.filas[z + 1].masasAgua[pos[2]].extraido.value = (
              parseFloat(
                planVolumenAct.filas[z].masasAgua[pos[2]].extraido.value
              ) +
              parseFloat(
                this.v_dataSet.planReajustes.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) -
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[pos[2]].extraido
                  .value
              )
            ).toString();
          }
          /* Vol melonares proxima quincena = vol melonares + reajuste melonares - trasvase melonares - aducción melonares */
          /************************************************************************************************* */
        } else if (
          this.v_dataSet.planTrasvases.filas[pos[1]].masasAgua[pos[2]].label ==
          "Los Melonares"
        ) {
          // INDICE
          let index = this.v_dataSet.planAduccion.filas[
            pos[1]
          ].masasAgua.findIndex((x) => x.label.includes("Los Melonares"));
          // Valor
          for (
            let z = parseInt(pos[1]);
            z < planVolumenAct.filas.length - 1;
            z++
          ) {
            planVolumenAct.filas[z + 1].masasAgua[pos[2]].extraido.value = (
              parseFloat(
                planVolumenAct.filas[z].masasAgua[pos[2]].extraido.value
              ) +
              parseFloat(
                this.v_dataSet.planReajustes.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) -
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) -
              parseFloat(
                this.v_dataSet.planAduccion.filas[z].masasAgua[index].extraido
                  .value &&
                  this.v_dataSet.planAduccion.filas[z].masasAgua[index]
                    .extraido.value != "-"
                  ? this.v_dataSet.planAduccion.filas[z].masasAgua[index]
                      .extraido.value
                  : "0"                  
              )
            ).toString();
          }
          /* Vol zufre proxima quincena = Volumen zufre + reajuste zufre - trasvase zufre + trasvase aracena */
          /************************************************************************************************* */
        } else if (
          this.v_dataSet.planTrasvases.filas[pos[1]].masasAgua[pos[2]].label ==
          "Zufre"
        ) {
          // INDICE
          let index = this.v_dataSet.planTrasvases.filas[
            pos[1]
          ].masasAgua.findIndex((x) => x.label.includes("Aracena"));
          // Valor
          for (
            let z = parseInt(pos[1]);
            z < planVolumenAct.filas.length - 1;
            z++
          ) {
            planVolumenAct.filas[z + 1].masasAgua[pos[2]].extraido.value = (
              parseFloat(
                planVolumenAct.filas[z].masasAgua[pos[2]].extraido.value
              ) +
              parseFloat(
                this.v_dataSet.planReajustes.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) -
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) +
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[index].extraido
                  .value
              )
            ).toString();
          }
          /* Vol minilla proxima quincena = Vol minilla + reajuste minilla - trasvase minilla + trasvase zufre - aducción minilla */
          /************************************************************************************************* */
        } else if (
          this.v_dataSet.planTrasvases.filas[pos[1]].masasAgua[pos[2]].label ==
          "La Minilla"
        ) {
          // INDICE
          let index = this.v_dataSet.planTrasvases.filas[
            pos[1]
          ].masasAgua.findIndex((x) => x.label.includes("Zufre"));
          let index2 = this.v_dataSet.planAduccion.filas[
            pos[1]
          ].masasAgua.findIndex((x) => x.label.includes("La Minilla"));
          // Valor
          for (
            let z = parseInt(pos[1]);
            z < planVolumenAct.filas.length - 1;
            z++
          ) {
            planVolumenAct.filas[z + 1].masasAgua[pos[2]].extraido.value = (
              parseFloat(
                planVolumenAct.filas[z].masasAgua[pos[2]].extraido.value
              ) +
              parseFloat(
                this.v_dataSet.planReajustes.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) -
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) +
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[index].extraido
                  .value
              ) -
              parseFloat(
                this.v_dataSet.planAduccion.filas[z].masasAgua[index2].extraido
                  .value &&
                  this.v_dataSet.planAduccion.filas[z].masasAgua[index2]
                    .extraido.value != "-"
                  ? this.v_dataSet.planAduccion.filas[z].masasAgua[index2]
                      .extraido.value
                  : "0"
              )
            ).toString();
          }
          /* Vol gergal proxima quincena = vol gergal + reajuste gergal - trasvase gergal  + trasvase minilla + trasvase cala - aducción gergal*/
          /*********************************************************************************************************************************** */
        } else if (
          this.v_dataSet.planTrasvases.filas[pos[1]].masasAgua[pos[2]].label ==
          "El Gergal"
        ) {
          // INDICE
          let index = this.v_dataSet.planTrasvases.filas[
            pos[1]
          ].masasAgua.findIndex((x) => x.label.includes("La Minilla"));
          let index2 = this.v_dataSet.planTrasvases.filas[
            pos[1]
          ].masasAgua.findIndex((x) => x.label.includes("Cala"));
          let index3 = this.v_dataSet.planAduccion.filas[
            pos[1]
          ].masasAgua.findIndex((x) => x.label.includes("El Gergal"));
          // Valor
          for (
            let z = parseInt(pos[1]);
            z < planVolumenAct.filas.length - 1;
            z++
          ) {
            planVolumenAct.filas[z + 1].masasAgua[pos[2]].extraido.value = (
              parseFloat(
                planVolumenAct.filas[z].masasAgua[pos[2]].extraido.value
              ) +
              parseFloat(
                this.v_dataSet.planReajustes.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) -
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[pos[2]].extraido
                  .value
              ) +
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[index].extraido
                  .value
              ) +
              parseFloat(
                this.v_dataSet.planTrasvases.filas[z].masasAgua[index2].extraido
                  .value
              ) -
              parseFloat(
                this.v_dataSet.planAduccion.filas[z].masasAgua[index3].extraido
                  .value &&
                  this.v_dataSet.planAduccion.filas[z].masasAgua[index3]
                    .extraido.value != "-"
                  ? this.v_dataSet.planAduccion.filas[z].masasAgua[index3]
                      .extraido.value
                  : "0"
              )
            ).toString();
          }
        }
        /*await console.log(
          this.v_dataSet.planVolumen.filas[3].masasAgua[pos[2]].extraido.value,
          planVolumenAct.filas[parseInt(pos[1]) + 1].masasAgua[pos[2]].extraido
            .value
        );*/
        return planVolumenAct;
      } catch (e) {
        console.log("Ha ocurrido un error calculando: " + e);
        console.log(parseInt(pos[1]) + 1);
      }
    },

    //Funcion para copiar el objeto y que se deslinke la misma referencia en memoria
    copyObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    /* HOOK para tener reactivamente el nombre dle fichero que se va a subir */
    updateFilename(filename) {
      this.attachmentFilename = filename;
    },
    /* Trigger para forzar el cuadro de seleccion dle input pulsando en otro sitio */
    triggerFakeInput() {
      document.getElementById("csvFileBtn").click();
    },
  },
  mounted() {},
};
</script>

<style scoped>
/* Sobreescribir configuraciones por defecto */
.card {
  max-width: 100%;
}

.nav-tabs .nav-link {
  color: gray;
  border: 0;
  border-bottom: 1px solid grey;
}

.nav-tabs .nav-link:hover {
  border: 0;
  border-bottom: 1px solid grey;
}

.nav-tabs .nav-link.active {
  color: #000000;
  border: 0;
  border-radius: 1;
  border-bottom: 2px solid #4ca1ef;
  background-color: #cde9ff;
}

.nav-item:hover {
  background-color: #f0f7fc;
  transition: background-color 0.5s ease;
}

.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #333333;
}

.tableTitle {
}
</style>