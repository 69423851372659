<template>
  <!-- Tabla de datos-->
  <div class="table-responsive mt-3">
    <table class="table table-hover">
      <thead class="table-light">
        <!-- Primera serie de columnas-->
        <tr>
          <th class="text-left ps-3">Embalse</th>
          <th class="text-center">Total hm3</th>
        </tr>
      </thead>

      <tbody class="text-left dataTable">
        <tr>
          <td class="ps-3">Aracena</td>
          <td class="text-center">{{ aracenaVolTotal }}</td>
        </tr>
        <tr>
          <td class="ps-3">Zufre</td>
          <td class="text-center">{{ zufreVolTotal }}</td>
        </tr>
        <tr>
          <td class="ps-3">La Minilla</td>
          <td class="text-center">{{ minillaVolTotal }}</td>
        </tr>
        <tr>
          <td class="ps-3">El Gergal</td>
          <td class="text-center">{{ gergalVolTotal }}</td>
        </tr>   
        <tr>
          <td class="ps-3">Cala</td>
          <td class="text-center">{{ calaVolTotal }}</td>
        </tr>           
        <tr class="d-none">
          <td class="ps-3">Pintado</td>
          <td class="text-center">0</td>
        </tr>         
        <tr class="d-none">
          <td class="ps-3">Río</td>
          <td class="text-center">0</td>
        </tr>  
        <tr>
          <td class="ps-3">Los Melonares</td>
          <td class="text-center">{{ melonaresVolTotal }}</td>
        </tr>               
        <tr>
          <td class="ps-3"><b>Total</b></td>
          <td class="text-center">
            <b>{{ genVolTotal }}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    dataSet: {
      type: Object,
      required: true,
    },
    viewMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  watch: {},
  computed: {
    aracenaVolTotal: function (val) {
      if(this.dataSet && this.dataSet.planTrasvases){
        let sum = 0;
        let index = -1;
        for(let i=0; i<this.dataSet.planTrasvases.filas.length; i++){
            index = (this.dataSet.planTrasvases.filas[i].masasAgua).findIndex(x =>  x.label.includes("Aracena"));
            sum = sum + ((this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value != '-' && typeof  this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value !== "undefined" )? parseFloat(this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value) : 0 )
        }
        return sum.toFixed(3);
      }else{
         return 0;
      }
    },

    zufreVolTotal: function (val) {
      if(this.dataSet && this.dataSet.planTrasvases){
        let sum = 0;
        let index = -1;
        for(let i=0; i<this.dataSet.planTrasvases.filas.length; i++){
            index = (this.dataSet.planTrasvases.filas[i].masasAgua).findIndex(x =>  x.label.includes("Zufre"));
            sum = sum + ((this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value != '-' && typeof this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value !== "undefined" ) ? parseFloat(this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value) : 0 )
        }
        return (sum - this.aracenaVolTotal).toFixed(3);
      }else{
         return 0;
      }
    },

    minillaVolTotal: function (val) {
      if(this.dataSet && this.dataSet.planTrasvases){
        let sum = 0;
        let sum2 = 0;
        let sum3 = 0;
        let index = -1;
        let index2 = -1;
        let index3 = -1;
        for(let i=0; i<this.dataSet.planTrasvases.filas.length; i++){
            index = (this.dataSet.planTrasvases.filas[i].masasAgua).findIndex(x =>  x.label.includes("Minilla"));
            sum = sum + ((this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value != '-' && typeof this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value !== "undefined" ) ? parseFloat(this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value) : 0 )
        }
        for(let i=0; i<this.dataSet.planAduccion.filas.length; i++){
            index2 = (this.dataSet.planAduccion.filas[i].masasAgua).findIndex(x =>  x.label.includes("Minilla"));
            sum2 = sum2 + ((this.dataSet.planAduccion.filas[i].masasAgua[index2].extraido.value != '-' && typeof this.dataSet.planAduccion.filas[i].masasAgua[index2].extraido.value !== "undefined" ) ? parseFloat(this.dataSet.planAduccion.filas[i].masasAgua[index2].extraido.value) : 0 )
        }     
        for(let i=0; i<this.dataSet.planTrasvases.filas.length; i++){
            index3 = (this.dataSet.planTrasvases.filas[i].masasAgua).findIndex(x =>  x.label.includes("Zufre"));
            sum3 = sum3 + ((this.dataSet.planTrasvases.filas[i].masasAgua[index3].extraido.value != '-' && typeof this.dataSet.planTrasvases.filas[i].masasAgua[index3].extraido.value !== "undefined" ) ? parseFloat(this.dataSet.planTrasvases.filas[i].masasAgua[index3].extraido.value) : 0 )
        }          
        return (sum + sum2 - sum3).toFixed(3);
      }else{
         return 0;
      }


    },

    gergalVolTotal: function (val) {
      if(this.dataSet && this.dataSet.planAduccion){
        let sum = 0;
        let sum2= 0;
        let index = -1;
        let index2 = -1;
        for(let i=0; i<this.dataSet.planAduccion.filas.length; i++){
            index = (this.dataSet.planAduccion.filas[i].masasAgua).findIndex(x =>  x.label.includes("Gergal"));
            sum = sum + ((this.dataSet.planAduccion.filas[i].masasAgua[index].extraido.value != '-' && typeof this.dataSet.planAduccion.filas[i].masasAgua[index].extraido.value !== "undefined" ) ? parseFloat(this.dataSet.planAduccion.filas[i].masasAgua[index].extraido.value) : 0 )
        }
        for(let i=0; i<this.dataSet.planTrasvases.filas.length; i++){
            index2 = (this.dataSet.planTrasvases.filas[i].masasAgua).findIndex(x =>  x.label.includes("Minilla"));
            sum2 = sum2 + ((this.dataSet.planTrasvases.filas[i].masasAgua[index2].extraido.value != '-' && typeof this.dataSet.planTrasvases.filas[i].masasAgua[index2].extraido.value !== "undefined" ) ? parseFloat(this.dataSet.planTrasvases.filas[i].masasAgua[index2].extraido.value) : 0 )
        }        
        return (sum - sum2 - this.calaVolTotal).toFixed(3);
      }else{
         return 0;
      }
    },    

    calaVolTotal: function (val) {
      if(this.dataSet && this.dataSet.planTrasvases){
        let sum = 0;
        let index = -1;
        for(let i=0; i<this.dataSet.planTrasvases.filas.length; i++){
            index = (this.dataSet.planTrasvases.filas[i].masasAgua).findIndex(x =>  x.label.includes("Cala"));
            sum = sum + ((this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value != '-' && typeof this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value !== "undefined" ) ? parseFloat(this.dataSet.planTrasvases.filas[i].masasAgua[index].extraido.value) : 0 )
        }
        return sum.toFixed(3);
      }else{
         return 0;
      }
    },
 
     melonaresVolTotal: function (val) {
      if(this.dataSet && this.dataSet.planAduccion){
        let sum = 0;
        let index = -1;
        for(let i=0; i<this.dataSet.planAduccion.filas.length; i++){
            index = (this.dataSet.planAduccion.filas[i].masasAgua).findIndex(x =>  x.label.includes("Melonares"));
            sum = sum + ((this.dataSet.planAduccion.filas[i].masasAgua[index].extraido.value != '-' && typeof this.dataSet.planAduccion.filas[i].masasAgua[index].extraido.value !== "undefined" ) ? parseFloat(this.dataSet.planAduccion.filas[i].masasAgua[index].extraido.value) : 0 )
        }
        return sum.toFixed(3);
      }else{
         return 0;
      }
    },

    genVolTotal: function (val) {
      return (parseFloat(this.aracenaVolTotal) + parseFloat(this.zufreVolTotal) + parseFloat(this.minillaVolTotal) + parseFloat(this.gergalVolTotal) + parseFloat(this.calaVolTotal) + parseFloat(this.melonaresVolTotal)).toFixed(3);
    },

  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
/* Estilo de la tabla */
table {
  border: 1px solid #dfe6ec;
}
table th {
  font-size: 0.9rem;
  border-right: 1px solid #dfe6ec;
}

table td {
  font-size: 0.85rem;
  border-right: 1px solid #dfe6ec;
}
.legend{
  font-size: 0.9rem;
  padding: 2px;
}
</style>